import React, { useEffect, useState } from "react";
import {
  useCoupanListuniQuery,
  useCreateCoupanUniversalMutation,
  useDeleteUniCoupanMutation,
  useUpdateUniCoupanMutation,
} from "../services/Post";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";

function CoupanUniversal() {
  const [universalCoupan] = useCreateCoupanUniversalMutation();
  const [updateUniCpn] = useUpdateUniCoupanMutation();
  const [delUniCpn] = useDeleteUniCoupanMutation();
  const { data: coupans } = useCoupanListuniQuery();
  const [coupanTitle, setCoupanTitle] = useState("");
  const [discountType, setDiscountType] = useState("");
  const [coupanCode, setCoupanCode] = useState("");
  const [totalUsers, setTotalUsers] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [item, setItem] = useState("");
  const [coupanTitle2, setCoupanTitle2] = useState(item?.coupanName || "");
  const [discountType2, setDiscountType2] = useState(item?.Discount || "");
  const [coupanCode2, setCoupanCode2] = useState(item?.coupanCode || "");
  const [startDate2, setStartDate2] = useState(
    item?.startDate?.slice(0, 10) || ""
  );
  const [endDate2, setEndDate2] = useState(item?.endDate?.slice(0, 10) || "");
  const [coupanList, setCoupanList] = useState([]);

  useEffect(() => {
    if (coupans?.results?.list?.length > 0) {
      setCoupanList(coupans?.results?.list);
    }
  }, [coupans]);

  const handleCreateCoupan = async (e) => {
    e.preventDefault();
    const data = {
      coupanName: coupanTitle,
      endDate: endDate,
      startDate: startDate,
      Discount: discountType,
      coupanCode: coupanCode,
      user: totalUsers,
    };

    try {
      const response = await universalCoupan(data);
      console.log("general coupan", response);

      if (response?.data?.message === "Success") {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Coupon created successfully!",
        });
        window?.location?.reload();
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Coupon creation failed!",
        });
      }
    } catch (error) {
      console.error("Error creating coupon", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred while creating the coupon.",
      });
    }
  };

  const handleGenDelete = (item) => {
    console.log("item id", item?._id);
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        delUniCpn(item?._id);
        Swal.fire(
          "Deleted!",
          `${item?.userName}  item has been deleted.`,
          "success"
        ).then(() => {
          const updatedOfferList = coupanList.filter(
            (offer) => offer._id !== item?._id
          );
          setCoupanList(updatedOfferList);
        });
      }
    });
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    const data = {
      coupanName: coupanTitle2,
      endDate: endDate2,
      startDate: startDate2,
      Discount: discountType2,
      coupanCode: coupanCode,
      id: item?._id,
    };

    try {
      const res = await updateUniCpn(data);
      console.log("res", res);

      if (res?.data?.message === "Success") {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Coupon updated successfully!",
          confirmButtonText: "OK",
        }).then(() => {
          window.location.reload();
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Coupon update failed. Please try again.",
          confirmButtonText: "OK",
        });
      }
    } catch (error) {
      console.error("An error occurred:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred. Please try again.",
        confirmButtonText: "OK",
      });
    }
  };

  const handleItem = (item) => {
    setItem(item);
  };

  return (
    <>
      <div
        className="tab-pane fade"
        id="profile"
        role="tabpanel"
        aria-labelledby="profile-tab"
      >
        <div className="row p-4 mx-0">
          <form
            className="form-design help-support-form row align-items-end"
            action=""
            onSubmit={handleCreateCoupan}
          >
            <div className="form-group col-4">
              <label htmlFor="coupanTitle">
                Coupon Title
                <span className="required-field text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                name="coupanTitle"
                id="coupanTitle"
                value={coupanTitle}
                onChange={(e) => setCoupanTitle(e.target.value)}
                required
                minLength="3"
              />
            </div>

            <div className="form-group col-4">
              <label htmlFor="coupanCode">
                Coupon Code
                <span className="required-field text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                name="coupanCode"
                id="coupanCode"
                value={coupanCode}
                onChange={(e) => setCoupanCode(e.target.value)}
                required
                minLength="3"
              />
            </div>

            <div className="form-group col-4">
              <label htmlFor="startDate">
                Start Date
                <span className="required-field text-danger">*</span>
              </label>
              <input
                type="date"
                className="form-control"
                name="startDate"
                id="startDate"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                required
                minLength="3"
              />
            </div>
            <div className="form-group col-4">
              <label htmlFor="endDate">
                End Date
                <span className="required-field text-danger">*</span>
              </label>
              <input
                type="date"
                className="form-control"
                name="endDate"
                id="endDate"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                required
                minLength="3"
              />
            </div>

            <div className="form-group col-4">
              <label htmlFor="discountType">
                Discount (%)
                <span className="required-field text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                name="discountType"
                id="discountType"
                value={discountType}
                onChange={(e) => setDiscountType(e.target.value)}
                required
                // minLength="3"
              />
            </div>
            <div className="form-group col-4">
              <label htmlFor="totalUsers">
                Total Users
                <span className="required-field text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                name="totalUsers"
                id="totalUsers"
                value={totalUsers}
                onChange={(e) => setTotalUsers(e.target.value)}
                required
                // minLength="3"
              />
            </div>
            <div className="form-group mb-0 mt-3 col-12 text-center">
              <button className="comman_btn">
                <span>Create</span>
              </button>
            </div>
          </form>
        </div>
        <div className="row px-4 py-4 mx-0">
          <div className="col-12 inner_design_comman border">
            <div className="row comman_header justify-content-between">
              <div className="col-auto">
                <h2>Universal Coupan</h2>
              </div>
            </div>
            <form
              className="form-design py-4 px-3 help-support-form row align-items-end justify-content-between"
              action=""
            >
              <div className="form-group mb-0 col-5">
                <label htmlFor="">From</label>
                <input type="date" className="form-control" />
              </div>
              <div className="form-group mb-0 col-5">
                <label htmlFor="">To</label>
                <input type="date" className="form-control" />
              </div>
              <div className="form-group mb-0 col-2">
                <button className="comman_btn d-flex w-100">
                  <span>Search</span>
                </button>
              </div>
            </form>
            <div className="row">
              <div className="col-12 comman_table_design px-0">
                <div className="table-responsive">
                  <table className="table mb-0">
                    <thead>
                      <tr>
                        <th>S.No.</th>
                        <th>Coupan Title </th>
                        <th>Coupan Code</th>
                        <th>Discount(%)</th>
                        <th>Available For</th>
                        <th>Total Used</th>
                        <th>Use For</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {coupanList
                        ?.slice()
                        ?.reverse()
                        ?.map((item, ind) => {
                          return (
                            <tr key={ind}>
                              <td>{ind + 1} </td>
                              <td>{item?.coupanName} </td>
                              <td>{item?.coupanCode || ""} </td>
                              <td> {item?.Discount} </td>
                              <td> {item?.user} </td>
                              <td> {item?.total} </td>
                              <td> {item?.subTotal} </td>
                              <td>{item?.startDate?.slice(0, 10)}</td>
                              <td>{item?.endDate?.slice(0, 10)}</td>

                              <td>
                                <Link
                                  className="comman_btn table_viewbtn"
                                  to="#"
                                  data-bs-toggle="modal"
                                  data-bs-target="#staticBackdropuniCoupan"
                                  onClick={() => handleItem(item)}
                                >
                                  <span>Edit</span>
                                </Link>
                                <Link
                                  className="comman_btn bg-danger table_viewbtn ms-1"
                                  to="#"
                                  onClick={(e) => handleGenDelete(item)}
                                >
                                  <span>Delete</span>
                                </Link>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade Edit_help Edit_modal"
        id="staticBackdropuniCoupan"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                Update Coupan
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form
                className="form-design help-support-form py-4 px-3 row align-items-start justify-content-center"
                action=""
                onSubmit={handleUpdate}
              >
                <div className="form-group mb-0 col-6">
                  <label htmlFor="coupanTitle2">
                    Coupan Title
                    <span className="required-field text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="coupanTitle2"
                    id="coupanTitle2"
                    defaultValue={item?.coupanName || ""}
                    onChange={(e) => setCoupanTitle2(e.target.value)}
                    required
                  />
                </div>

                <div className="form-group col-6 ">
                  <label htmlFor="discountType">
                    Discount (%)
                    <span className="required-field text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    name="discountType"
                    id="discountType"
                    defaultValue={item?.Discount || ""}
                    onChange={(e) => setDiscountType2(e.target.value)}
                    required
                  />
                </div>

                <div className="form-group col-6 mt-3">
                  <label htmlFor="startDate2">
                    Start Date
                    <span className="required-field text-danger">*</span>
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    name="startDate2"
                    id="startDate2"
                    defaultValue={item?.startDate?.slice(0, 10) || ""}
                    onChange={(e) => setStartDate2(e.target.value)}
                    required
                  />
                </div>
                <div className="form-group col-6 mt-3">
                  <label htmlFor="endDate2">
                    End Date
                    <span className="required-field text-danger">*</span>
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    name="endDate2"
                    id="endDate2"
                    defaultValue={item?.endDate?.slice(0, 10) || ""}
                    onChange={(e) => setEndDate2(e.target.value)}
                    required
                  />
                </div>

                <div className="form-group col-12 text-center">
                  <button className="comman_btn mt-4">
                    {" "}
                    <span>Create</span>{" "}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CoupanUniversal;
