import React, { useEffect, useRef, useState } from "react";
import Sidebar from "../Sidebar";
import {
  useAgentsListQuery,
  useCreateAgentsMutation,
  useDeleteAgentMutation,
  useFilterAgentsByDateQuery,
  useUpdateAgentsMutation,
} from "../../services/Post";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

function Agents() {
  const [show, setShow] = useState(true);
  const { data: agents } = useAgentsListQuery();
  const [deleteAgent] = useDeleteAgentMutation();
  const [createAgent] = useCreateAgentsMutation();
  const [updateAgent] = useUpdateAgentsMutation();
  const [agentList, setAgentList] = useState([]);
  const [item, setItem] = useState("");
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [password, setPassword] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [idNumber, setIdNumber] = useState("");
  const [address, setAddress] = useState("");
  const [documentname, setDocumentname] = useState("");
  const [userName2, setUserName2] = useState(item?.userName || "");
  const [userEmail2, setUserEmail2] = useState(item?.userEmail || "");
  const [password2, setPassword2] = useState(item?.password || "");
  const [mobileNumber2, setMobileNumber2] = useState(item?.mobileNumber || "");
  const [idNumber2, setIdNumber2] = useState(item?.idNumber || "");
  const [address2, setAddress2] = useState(item?.address || "");
  const [documentname2, setDocumentname2] = useState(item?.documentname || "");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [formData, setFormData] = useState([]);
  const [formData1, setFormData1] = useState([]);
  const [formDataupload, setFormDataupload] = useState([]);
  const fileInputRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState([]);

  const handleChooseFileClick = () => {
    fileInputRef.current.click();
  };

  const navigate = useNavigate();

  const { data: filteredAgents } = useFilterAgentsByDateQuery(
    {
      from: fromDate,
      to: toDate,
    },
    {
      skip: !fromDate || !toDate,
    }
  );

  // const handleImageUpload = (event) => {
  //   const file = event.target.files[0];
  //   // setSelectedImage(URL.createObjectURL(file));
  //   setFormData({ ...formData, uploadImage: event.target.files[0] });
  // };
  const handleImageUploadupdate = (event) => {
    const file = event.target.files[0];
    // setSelectedImage(URL.createObjectURL(file));
    setFormDataupload({
      ...formDataupload,
      uploadImage: event.target.files[0],
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData1({ ...formData1, [name]: value });
    if (name === "dob") {
      const dateRegex = /^\d{4}\/\d{2}\/\d{2}$/;
      const isValidFormat = dateRegex.test(value);
      setFormData1({ ...formData1, dob: value, isValidFormat });
    }
  };

  useEffect(() => {
    if (filteredAgents?.results?.list) {
      setAgentList(filteredAgents.results.list);
    }
  }, [filteredAgents]);

  console.log("agentList", agentList);

  useEffect(() => {
    if (agents) {
      setAgentList(agents?.results?.list);
    }
  }, [agents]);

  const handleImageUpload = (e, key) => {
    const files = e.target.files;
    let img = [...selectedImage];
    for (const file of files) {
      img.push(file);
    }
    console.log("img", img);
    setSelectedImage(img);
  };

  const handleOnSave = async (e) => {
    setShow(false);
    e.preventDefault();
    try {
      const data = new FormData();
      if (formData1.userName) {
        data.append("userName", formData1.userName);
      }

      // data.append("companyName_en", formData1.companyName);
      // data.append("password", formData1.password);
      if (formData1.userEmail) {
        data.append("userEmail", formData1.userEmail);
      }
      if (formData1.password) {
        data.append("password", formData1.password);
      }

      if (formData1.mobileNumber) {
        data.append("mobileNumber", formData1.mobileNumber);
      }

      if (formData1.idNumber) {
        data.append("idNumber", formData1.idNumber);
      }

      if (formData1.address) {
        data.append("address", formData1.address);
      }

      if (selectedImage && selectedImage.length > 0) {
        selectedImage.map((item, index) => {
          data.append(`document`, item);
        });
      }

      // if (formData.uploadImage) {
      //   data.append("document", formData.uploadImage);
      // }
      if (formData1.documentName) {
        data.append("documentName", formData1.documentName);
      }

      const response = await createAgent({
        // id: userId,
        formData: data,
      });
      setFormData(response.data.results);
      setShow(true);

      await Swal.fire({
        title: "Success",
        text: "New Agent Added successfully!",
        icon: "success",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
      });
      // navigate("/login");
      window?.location?.reload();
    } catch (error) {
      console.error(error.response?.data);
    }
  };

  const handleCreateAgents = async (e) => {
    e.preventDefault();
    const data = {
      userName: userName,
      userEmail: userEmail,
      password: password,
      mobileNumber: mobileNumber,
      idNumber: idNumber,
      address: address,
      documentname: documentname,
    };

    try {
      const response = await createAgent(data);
      console.log("general coupan", response);

      if (response?.data?.message === "Success") {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Agent created successfully!",
        });
        window?.location?.reload();
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Coupon creation failed!",
        });
      }
    } catch (error) {
      console.error("Error creating coupon", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred while creating the coupon.",
      });
    }
  };

  const handleAgentDelete = (item) => {
    console.log("item id", item?._id);
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteAgent(item?._id);
        Swal.fire(
          "Deleted!",
          `${item?.userName}  item has been deleted.`,
          "success"
        ).then(() => {
          const updatedOfferList = agentList.filter(
            (offer) => offer._id !== item?._id
          );
          setAgentList(updatedOfferList);
        });
      }
    });
  };

  const handleOnUpdate = async (e) => {
    e.preventDefault();
    try {
      const data = new FormData();
      if (formData1.userName) {
        data.append("userName", formData1.userName);
      }

      if (formData1.userEmail) {
        data.append("userEmail", formData1.userEmail);
      }
      if (formData1.password) {
        data.append("password", formData1.password);
      }

      if (formData1.mobileNumber) {
        data.append("mobileNumber", formData1.mobileNumber);
      }

      if (formData1.idNumber) {
        data.append("idNumber", formData1.idNumber);
      }

      if (formData1.address) {
        data.append("address", formData1.address);
      }

      if (formData.uploadImage) {
        data.append("document", formData.uploadImage);
      }
      if (formData1.documentName) {
        data.append("documentName", formData1.documentName);
      }

      const response = await updateAgent({
        id: item?._id,
        formData: data,
      });
      setFormData(response.data.results);

      await Swal.fire({
        title: "Success",
        text: " Agent Updated successfully!",
        icon: "success",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
      });
      // navigate("/login");
      window?.location?.reload();
    } catch (error) {
      console.error(error.response?.data);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    const data = {
      userName: userName2,
      userEmail: userEmail2,
      password: password2,
      mobileNumber: mobileNumber2,
      idNumber: idNumber2,
      address: address2,
      id: item?._id,
    };

    try {
      const res = await updateAgent(data);
      console.log("res", res);

      if (res?.data?.message === "Success") {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Agent updated successfully!",
          confirmButtonText: "OK",
        }).then(() => {
          window.location.reload();
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Coupon update failed. Please try again.",
          confirmButtonText: "OK",
        });
      }
    } catch (error) {
      console.error("An error occurred:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred. Please try again.",
        confirmButtonText: "OK",
      });
    }
  };

  const handleItem = (item) => {
    setItem(item);
  };

  // const handleCheckboxChange = async (e, categoryId) => {
  //   e.preventDefault();
  //   const newStatus = e.target.checked;
  //   const confirmationResult = await Swal.fire({
  //     title: "Confirm Status Change",
  //     text: "Do you want to change the status?",
  //     icon: "question",
  //     showCancelButton: true,
  //     confirmButtonText: "Yes",
  //     cancelButtonText: "No",
  //   });

  //   if (confirmationResult.isConfirmed) {
  //     const editStatus = {
  //       id: categoryId,
  //       status: newStatus,
  //     };
  //     try {
  //       await updateStatus(editStatus);
  //       Swal.fire({
  //         title: "Changes Saved",
  //         text: "The Status has been updated successfully.",
  //         icon: "success",
  //         confirmButtonText: "OK",
  //       }).then((result) => {
  //         if (result.isConfirmed) {
  //           window.location.reload();
  //         }
  //       });
  //     } catch (error) {}
  //   }
  // };
  return (
    <>
      <Sidebar Dash={"agents"} />

      <div className="admin_main">
        <div className="admin_main_inner">
          <div className="admin_panel_data height_adjust">
            <div className="row user-management justify-content-center">
              <div className="col-12">
                <div className="row mx-0">
                  <div className="col-12 text-end mb-4">
                    <Link
                      to="#"
                      className="comman_btn mb-3"
                      data-bs-toggle="modal"
                      data-bs-target="#createagent"
                    >
                      <span>+ Add Agent</span>
                    </Link>
                  </div>
                  <div className="col-12 design_outter_comman shadow">
                    <div className="row comman_header justify-content-between">
                      <div className="col-auto">
                        <h2>Agent Management</h2>
                      </div>
                    </div>
                    <form
                      className="form-design py-4 px-3 help-support-form row align-items-end justify-content-between"
                      action=""
                    >
                      <div className="form-group mb-0 col-5">
                        <label htmlFor="fromDate">From</label>
                        <input
                          type="date"
                          className="form-control"
                          id="fromDate"
                          value={fromDate}
                          onChange={(e) => setFromDate(e.target.value)}
                        />
                      </div>
                      <div className="form-group mb-0 col-5">
                        <label htmlFor="toDate">To</label>
                        <input
                          type="date"
                          className="form-control"
                          id="toDate"
                          value={toDate}
                          onChange={(e) => setToDate(e.target.value)}
                        />
                      </div>
                      <div className="form-group mb-0 col-auto">
                        <button className="comman_btn" disabled>
                          <span>Search</span>
                        </button>
                      </div>
                    </form>
                    <div className="row">
                      <div className="col-12 comman_table_design px-0">
                        <div className="table-responsive">
                          <table className="table mb-0">
                            <thead>
                              <tr>
                                <th>S.No.</th>
                                <th>Profile</th>
                                <th>User Name</th>
                                <th>Mobile Number</th>
                                <th>Email Address</th>
                                <th>Id Number</th>
                                <th>Password</th>
                                <th>Status</th>
                                <th>Date</th>

                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {agentList
                                ?.slice()
                                ?.reverse()
                                ?.map((ite, ind) => {
                                  return (
                                    <tr key={ind}>
                                      <td>{ind + 1} </td>
                                      <td>
                                        <img
                                          src={
                                            ite?.profile_Pic ||
                                            "../assets/img/profile_img1.png"
                                          }
                                          alt={ite?.userName?.slice(0, 1)}
                                          style={{
                                            border: "2px solid #53B0EE",
                                            borderRadius: "100%",
                                            height: "50px",
                                            width: "50px",
                                          }}
                                        />{" "}
                                      </td>
                                      <td>{ite?.userName} </td>
                                      <td>{ite?.mobileNumber} </td>
                                      <td>{ite?.userEmail} </td>
                                      <td> {ite?.idNumber} </td>
                                      <td>{ite?.password}</td>
                                      <td>
                                        <form className="table_btns d-flex align-items-center">
                                          <div className="check_toggle">
                                            <input
                                              className="d-none"
                                              defaultChecked={
                                                ite.status === "true"
                                                  ? true
                                                  : false
                                              }
                                              // status: item.status === "true" ? true : false,
                                              type="checkbox"
                                              name={`status_${ite._id}`}
                                              id={`status_${ite._id}`}
                                              // onChange={(e) =>
                                              //   handleCheckboxChange(
                                              //     e,
                                              //     item._id
                                              //   )
                                              // }
                                            />
                                            <label
                                            // htmlFor={`status_${item._id}`}
                                            ></label>
                                          </div>
                                        </form>
                                      </td>
                                      <td>{ite?.createdAt?.slice(0, 10)} </td>

                                      <td>
                                        {/* <Link
                                        className="comman_btn table_viewbtn"
                                        to="#"
                                      >
                                        <span>View</span>
                                      </Link> */}
                                        <Link
                                          data-bs-toggle="modal"
                                          data-bs-target="#edit"
                                          className="comman_btn table_viewbtn mx-1"
                                          to="#"
                                          onClick={() => handleItem(ite)}
                                        >
                                          <span>Edit</span>
                                        </Link>
                                        <Link
                                          className="comman_btn bg-danger table_viewbtn"
                                          to="#"
                                          onClick={(e) =>
                                            handleAgentDelete(ite)
                                          }
                                        >
                                          Delete
                                        </Link>
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade reply_modal"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content border-0">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                MESSAGES
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body py-4">
              <div className="chatpart_main">
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim
                  ut soluta, debitis provident reiciendis architecto.
                  Reprehenderit et labore saepe, dolor ullam commodi fugiat
                  dolorum tempora voluptatem explicabo delectus ducimus
                  quibusdam.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Edit Modal */}
      <div
        className="modal fade Edit_help Edit_modal"
        id="edit"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                Edit
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <form
                className="form-design row mx-0 py-2"
                action=""
                onSubmit={handleOnUpdate}
              >
                <div className="form-group col-6">
                  <label htmlFor="userName">USER NAME</label>
                  <input
                    className="form-control"
                    type="text"
                    id="userName"
                    name="userName"
                    defaultValue={item?.userName}
                    // onChange={(e) => setUserName2(e.target.value)}
                    // value={formData1.userName}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-group col-6">
                  <label htmlFor="mobileNumber">MOBILE NUMBER</label>
                  <input
                    className="form-control"
                    type="text"
                    id="mobileNumber"
                    name="mobileNumber"
                    defaultValue={item?.mobileNumber}
                    // onChange={(e) => setMobileNumber2(e.target.value)}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-group col-6">
                  <label htmlFor="userEmail">EMAIL ADDRESS</label>
                  <input
                    className="form-control"
                    type="text"
                    id="userEmail"
                    name="userEmail"
                    defaultValue={item?.userEmail}
                    // onChange={(e) => setUserEmail2(e.target.value)}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-group col-6">
                  <label htmlFor="password">Password</label>
                  <input
                    className="form-control"
                    type="text"
                    id="password"
                    name="password"
                    defaultValue={item?.password}
                    // onChange={(e) => setPassword2(e.target.value)}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-group col-6">
                  <label htmlFor="idNumber">Id Number</label>
                  <input
                    className="form-control"
                    type="text"
                    id="idNumber"
                    name="idNumber"
                    defaultValue={item?.idNumber}
                    // onChange={(e) => setIdNumber2(e.target.value)}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-group col-6">
                  <label htmlFor="address">Address</label>
                  <textarea
                    className="form-control"
                    type="text"
                    id="address"
                    name="address"
                    defaultValue={item?.address}
                    // onChange={(e) => setAddress2(e.target.value)}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="form-group col-6">
                  <label htmlFor="documentName">Document Name</label>
                  <input
                    className="form-control"
                    type="text"
                    id="documentName"
                    name="documentName"
                    defaultValue={item?.documentName}
                    // onChange={(e) => setDocumentname2(e.target.value)}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="form-group mb-0 col-6 choose_file position-relative">
                  <span>Document </span>{" "}
                  <label htmlFor="upload_video" style={{ width: "31%" }}>
                    <i className="fal fa-camera me-1" />
                    Choose File
                  </label>{" "}
                  <input
                    type="file"
                    className="form-control"
                    // defaultValue={item ? item?.document[0] : ""}
                    name="upload_video"
                    id="upload_video"
                    onChange={(e) => handleImageUploadupdate(e, "uploadImage")}
                  />
                </div>
                <div className="form-group col-12 text-center mb-0">
                  <button type="submit" className="comman_btn">
                    <span>Update</span>
                  </button>
                  <a
                    href="javascript:;"
                    data-bs-dismiss="modal"
                    className="comman_btn ms-3 bg-danger"
                  >
                    <span>Cancel</span>
                  </a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade Edit_help Edit_modal"
        id="createagent"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                Add Agent
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <form
                className="form-design row mx-0 py-2"
                action=""
                onSubmit={handleOnSave}
              >
                <div className="form-group col-6">
                  <label htmlFor="userName">USER NAME</label>
                  <input
                    className="form-control"
                    type="text"
                    id="userName"
                    name="userName"
                    placeholder="Enter User Name..."
                    // defaultValue=""
                    value={formData1.userName}
                    // onChange={(e) => setUserName(e.target.value)}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-group col-6">
                  <label htmlFor="mobileNumber">MOBILE NUMBER</label>
                  <input
                    className="form-control"
                    type="text"
                    id="mobileNumber"
                    name="mobileNumber"
                    placeholder="Enter Mobile Number..."
                    // defaultValue=""
                    value={formData1.mobileNumber}
                    // onChange={(e) => setMobileNumber(e.target.value)}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-group col-6">
                  <label htmlFor="userEmail">EMAIL ADDRESS</label>
                  <input
                    className="form-control"
                    type="text"
                    id="userEmail"
                    name="userEmail"
                    placeholder="Enter Your Email..."
                    // defaultValue=""
                    value={formData1.userEmail}
                    // onChange={(e) => setUserEmail(e.target.value)}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-group col-6">
                  <label htmlFor="password">Password</label>
                  <input
                    className="form-control"
                    type="text"
                    id="password"
                    name="password"
                    placeholder="Enter Password"
                    // defaultValue=""
                    value={formData1.password}
                    // onChange={(e) => setPassword(e.target.value)}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-group col-6">
                  <label htmlFor="idNumber">Id Number</label>
                  <input
                    className="form-control"
                    type="text"
                    id="idNumber"
                    name="idNumber"
                    placeholder="Enter Your Id"
                    // defaultValue=""
                    value={formData1.idNumber}
                    // onChange={(e) => setIdNumber(e.target.value)}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-group col-6">
                  <label htmlFor="address">Address</label>
                  <textarea
                    className="form-control"
                    type="text"
                    id="address"
                    name="address"
                    placeholder="Enter Address..."
                    // defaultValue=""
                    value={formData1.address}
                    // onChange={(e) => setAddress(e.target.value)}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="form-group col-6">
                  <label htmlFor="documentName">Document Name</label>
                  <input
                    className="form-control"
                    type="text"
                    id="documentName"
                    name="documentName"
                    placeholder="Enter Your Document Name..."
                    // defaultValue=""
                    value={formData1.documentName}
                    // onChange={(e) => setDocumentname(e.target.value)}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="form-group mb-0 col-6 choose_file position-relative">
                  <span>Document </span>{" "}
                  <label
                    htmlFor="upload_video"
                    style={{ width: "31%", cursor: "pointer" }}
                    onClick={handleChooseFileClick}
                  >
                    <i className="fal fa-camera me-1" />
                    Choose File
                  </label>{" "}
                  <input
                    type="file"
                    className="form-control"
                    defaultValue=""
                    name="upload_video"
                    id="upload_video"
                    ref={fileInputRef}
                    onChange={(e) => handleImageUpload(e, "uploadImage")}
                    multiple
                  />
                </div>

                {show ? (
                  <div className="form-group col-12 text-center mb-0">
                    <button type="submit" className="comman_btn">
                      <span>Add</span>
                    </button>
                    <Link
                      to="#"
                      data-bs-dismiss="modal"
                      className="comman_btn ms-3 bg-danger"
                    >
                      <span>Cancel</span>
                    </Link>
                  </div>
                ) : (
                  <div className="form-group col-12 text-center mb-0">
                    <button
                      type="submit"
                      className="comman_btn bg-danger"
                      style={{ cursor: "not-allowed" }}
                      disabled
                    >
                      <span>Adding...</span>
                    </button>
                    <Link
                      to="#"
                      data-bs-dismiss="modal"
                      className="comman_btn ms-3 bg-danger"
                    >
                      <span>Cancel</span>
                    </Link>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Agents;
