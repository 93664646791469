import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import Sidebar from "./Sidebar";
import {
  useDeleteContactMutation,
  useDeleteHelpManagementListMutation,
  useFilterHelpListByDateQuery,
  useGetHelpListQuery,
  useUpdateHelpMutation,
} from "../services/Post";
import Spinner from "./Spinner";
import { MessageBox } from "react-chat-elements";

function Help(props) {
  const [loading, setLoading] = useState(true);
  const { data: helpAll } = useGetHelpListQuery();
  const [deleteHelp, res] = useDeleteContactMutation();
  const [updateHelpList] = useUpdateHelpMutation();
  const [helpList, setHelpList] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [itemId, setItemId] = useState([]);
  const [userName, setUserName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState("");
  const [chatItem, setChatItem] = useState("");

  const [status2, setStatus2] = useState("");

  const { data: filteredHelp } = useFilterHelpListByDateQuery(
    {
      from: fromDate,
      to: toDate,
    },
    {
      skip: !fromDate || !toDate,
    }
  );

  useEffect(() => {
    if (filteredHelp?.results?.contactList) {
      setHelpList(filteredHelp?.results?.contactList);
    }
  }, [filteredHelp]);

  useEffect(() => {
    fetchHelpList(helpAll);
  }, [helpAll]);

  const fetchHelpList = (data) => {
    if (data) {
      props.setProgress(10);
      setLoading(true);
      setHelpList(data?.results?.contactList);
      setLoading(false);
      props.setProgress(100);
    }
  };

  const handleItem = (item) => {
    setItemId(item?._id);
    setUserName(item?.userName || "");
    setMobile(item?.mobileNumber || "");
    setEmail(item?.userEmail || "");
    setMessage(item?.descripation || []);
    setStatus(item?.status || 0);
  };

  const handleSaveChanges = async (e) => {
    e.preventDefault();
    const editHelp = {
      id: itemId,
      status: status2,
    };
    try {
      await updateHelpList(editHelp);
      Swal.fire({
        icon: "success",
        title: "Changes Saved",
        text: "The Status has been updated successfully.",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred while updating the subcategory.",
      });
    }
  };

  const handleChat = (item) => {
    setChatItem(item);
  };

  return (
    <>
      {loading}
      <Sidebar Dash={"help"} />
      <div className="admin_main">
        <div className="admin_main_inner">
          <div className="admin_panel_data height_adjust">
            <div className="row user-management justify-content-center">
              <div className="col-12">
                <div className="row mx-0">
                  <div className="col-12 design_outter_comman shadow">
                    <div className="row comman_header justify-content-between">
                      <div className="col-auto">
                        <h2>Help &amp; Support</h2>
                      </div>
                    </div>
                    <form
                      className="form-design py-4 px-3 help-support-form row align-items-end justify-content-between"
                      action=""
                    >
                      <div className="form-group mb-0 col-5">
                        <label htmlFor="fromDate">From</label>
                        <input
                          type="date"
                          className="form-control"
                          id="fromDate"
                          value={fromDate}
                          onChange={(e) => setFromDate(e.target.value)}
                        />
                      </div>
                      <div className="form-group mb-0 col-5">
                        <label htmlFor="toDate">To</label>
                        <input
                          type="date"
                          className="form-control"
                          id="toDate"
                          value={toDate}
                          onChange={(e) => setToDate(e.target.value)}
                        />
                      </div>
                      <div className="form-group mb-0 col-auto">
                        <Link className="comman_btn" disabled>
                          <span>Search</span>
                        </Link>
                      </div>
                    </form>
                    <div className="row">
                      <div className="col-12 comman_table_design px-0">
                        <div className="table-responsive">
                          <table className="table mb-0">
                            <thead>
                              <tr>
                                <th>S.No.</th>
                                <th>User Name</th>
                                <th>Mobile Number</th>
                                <th>Email Address</th>
                                <th>Messages</th>
                                <th>Date</th>
                                <th>Status</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            {loading ? (
                              <div style={{ marginLeft: "455px" }}>
                                <Spinner />
                              </div>
                            ) : (
                              <tbody>
                                {helpList?.map((item, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>{index + 1} </td>
                                      <td> {item?.userName} </td>
                                      <td>{item?.mobileNumber} </td>
                                      <td> {item?.userEmail} </td>
                                      <td>
                                        {item?.descripation?.slice(0, 15)}...{" "}
                                      </td>
                                      <td> {item?.createdAt?.slice(0, 10)} </td>
                                      <td>
                                        <div
                                          className={
                                            item?.status === "Cancelled"
                                              ? "text-danger"
                                              : item?.status === "Pending"
                                              ? "text-warning"
                                              : item?.status === "progress"
                                              ? "text-info"
                                              : item?.status === "Fixed"
                                              ? "text-success"
                                              : item?.status === "Inprogress"
                                              ? "text-primary"
                                              : item?.status === "Delivered"
                                              ? "text-secondary"
                                              : "text-default"
                                          }
                                          style={{
                                            background:
                                              item?.status === "Cancelled"
                                                ? "#ffe5e5"
                                                : item?.status === "Pending"
                                                ? "#fff6e5"
                                                : item?.status === "progress"
                                                ? "#e5f0ff"
                                                : item?.status === "Fixed"
                                                ? "#e5ffe5"
                                                : item?.status === "Inprogress"
                                                ? "#e5e5ff"
                                                : item?.status === "Delivered"
                                                ? "#f3f3f3"
                                                : "#f9f9f9",
                                            borderRadius: "5px",
                                            padding: "2px 5px",
                                          }}
                                        >
                                          {item?.status}
                                        </div>{" "}
                                      </td>
                                      <td>
                                        <Link
                                          className="comman_btn table_viewbtn me-1"
                                          // to="/help-view"
                                          data-bs-toggle="modal"
                                          data-bs-target="#staticBackdropchat"
                                          to={"#"}
                                          onClick={() => handleChat(item)}
                                        >
                                          <span>Chat</span>
                                        </Link>
                                        <Link
                                          className="comman_btn table_viewbtn"
                                          // to="/help-view"
                                          to={`/help-view/${encodeURIComponent(
                                            JSON.stringify(item)
                                          )}`}
                                        >
                                          <span>View</span>
                                        </Link>
                                        <Link
                                          data-bs-toggle="modal"
                                          data-bs-target="#edit"
                                          className="comman_btn table_viewbtn mx-1"
                                          to="#"
                                          onClick={() => handleItem(item)}
                                        >
                                          <span>Edit</span>
                                        </Link>
                                        <Link
                                          className="comman_btn bg-danger table_viewbtn"
                                          to="#"
                                          onClick={() => {
                                            Swal.fire({
                                              title: "Are you sure?",
                                              text: "You won't be able to revert this!",
                                              icon: "warning",
                                              showCancelButton: true,
                                              confirmButtonColor: "#3085d6",
                                              cancelButtonColor: "#d33",
                                              confirmButtonText:
                                                "Yes, delete it!",
                                            }).then((result) => {
                                              if (result.isConfirmed) {
                                                deleteHelp(item?._id);
                                                Swal.fire(
                                                  "Deleted!",
                                                  `${item?.userName}  item has been deleted.`,
                                                  "success"
                                                ).then(() => {
                                                  const updatedOfferList =
                                                    helpList.filter(
                                                      (offer) =>
                                                        offer._id !== item?._id
                                                    );
                                                  setHelpList(updatedOfferList);
                                                });
                                              }
                                            });
                                          }}
                                        >
                                          Delete
                                        </Link>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            )}
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade reply_modal"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content border-0">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                MESSAGES
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body py-4">
              <div className="chatpart_main">
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim
                  ut soluta, debitis provident reiciendis architecto.
                  Reprehenderit et labore saepe, dolor ullam commodi fugiat
                  dolorum tempora voluptatem explicabo delectus ducimus
                  quibusdam.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Edit Modal */}
      <div
        className="modal fade Edit_help Edit_modal"
        id="edit"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                Edit
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <form
                className="form-design row mx-0 py-2"
                action=""
                onSubmit={handleSaveChanges}
              >
                {/* <div className="form-group col-12">
                  <label htmlFor="quesstioon">Descripation</label>
                  <textarea
                    className="form-control"
                    type="text"
                    id="quesstioon"
                    name="quesstioon"
                    defaultValue={message}
                    // onChange={(e) => setMessage2(e.target.value)}
                    readOnly
                  />
                </div> */}

                <div className="form-group col-12">
                  <form>
                    <select
                      className="form-select"
                      id="floatingSelect12"
                      aria-label="  select example"
                      defaultValue=" "
                      onChange={(e) => setStatus2(e.target.value)}
                    >
                      <option value=""> Status</option>
                      <option value="Pending">Pending</option>
                      <option value="progress">progress</option>
                      <option value="Fixed">Fixed</option>
                    </select>
                    {/* </div> */}
                  </form>
                </div>
                <div className="form-group col-12 text-center mb-0">
                  <button type="submit" className="comman_btn">
                    <span>Update</span>
                  </button>
                  <a
                    href="#"
                    data-bs-dismiss="modal"
                    className="comman_btn ms-3 bg-danger"
                  >
                    <span>Cancel</span>
                  </a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade reply_modal"
        id="staticBackdropchat"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content border-0">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                Chat with {chatItem?.userName}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>

            <div className="modal-body py-4 " id="chat">
              <div
                className="chatpart_main "
                id="chat2"
                //  ref={chatpartMainRef}
              >
                <div className="row mx-0 ">
                  <div className="col-12 user_chat mb-3">
                    <div className="row">
                      <div className="col-12 user_chat mb-md-3 mb-4">
                        <div className="row mx-0 justify-content-start">
                          <div className="col-auto">
                            <a href="javascript:;" className="chat_comman_pix">
                              <img
                                src={"assets/img/product1.png"}
                                height={50}
                                width={50}
                                alt=""
                              />
                            </a>
                          </div>
                          <div className="col-5 ps-0">
                            <div className="chat_comman_content">
                              <span>Me</span>
                              <p>
                                <img src="assets/img/waving-hand.png" alt="" />{" "}
                                Hey David Moore, are you available ? ..
                              </p>
                              <p className="">1:00 PM</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 mine_chat mb-md-3 mb-4">
                        <div className="row mx-0 justify-content-end">
                          <div className="col-5 pe-0">
                            <div className="chat_comman_content2 text-end">
                              <span>David Moore</span>
                              <p>Yes i am available. Lorem Ipsum . </p>
                            </div>
                          </div>
                          <div className="col-auto">
                            <Link to="#" className="chat_comman_pix">
                              <img
                                src={"assets/img/product1.png"}
                                height={50}
                                width={50}
                                alt=""
                              />
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 user_chat mb-md-3 mb-4">
                        <div className="row mx-0 justify-content-start">
                          <div className="col-auto">
                            <Link to="#" className="chat_comman_pix">
                              <img
                                src={"assets/img/product1.png"}
                                height={50}
                                width={50}
                                alt=""
                              />
                            </Link>
                          </div>
                          <div className="col-5 ps-0">
                            <div className="chat_comman_content">
                              <span>Me</span>
                              <p>
                                <img src="assets/img/waving-hand.png" alt="" />{" "}
                                Hey David Moore, are you available ? ..
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 mine_chat mb-md-3 mb-4">
                        <div className="row mx-0 justify-content-end">
                          <div className="col-5 pe-0">
                            <div className="chat_comman_content2 text-end">
                              <span>David Moore</span>
                              <p>Yes i am available. Lorem Ipsum . </p>
                            </div>
                          </div>
                          <div className="col-auto">
                            <Link to="#" className="chat_comman_pix">
                              <img
                                src={"assets/img/product1.png"}
                                height={50}
                                width={50}
                                alt=""
                              />
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 user_chat mb-md-3 mb-4">
                        <div className="row mx-0 justify-content-start">
                          <div className="col-auto">
                            <Link to="#" className="chat_comman_pix">
                              <img
                                src={"assets/img/product1.png"}
                                height={50}
                                width={50}
                                alt=""
                              />
                            </Link>
                          </div>
                          <div className="col-5 ps-0">
                            <div className="chat_comman_content">
                              <span>Me</span>
                              <p className="">
                                Hey David Moore, are you available ? ..
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 mine_chat mb-md-3 mb-4">
                        <div className="row mx-0 justify-content-end">
                          <div className="col-5 pe-0">
                            <div className="chat_comman_content2 text-end">
                              <span>David Moore</span>
                              <p>Yes i am available. Lorem Ipsum . </p>
                            </div>
                          </div>
                          <div className="col-auto">
                            <a href="javascript:;" className="chat_comman_pix">
                              <img
                                src={"assets/img/product1.png"}
                                height={50}
                                width={50}
                                alt=""
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                      {/* <MessageBox
                        position={"right"}
                        type={"text"}
                        // title={mainChat?.buyer?.full_name}
                        // text={mainChat?.concern}
                        // date={mainChat?.createdAt}
                      /> */}
                    </div>
                    {/* {mainChat?.images?.map((item) => (
                      <div className="row mt-1">
                        <MessageBox
                          position={"right"}
                          type={"photo"}
                          title={mainChat?.buyer?.full_name}
                          date={mainChat?.createdAt}
                          data={{
                            uri: item,
                            width: 50,
                          }}
                        />
                      </div>
                    ))} */}
                  </div>
                  {/* {(chat || [])?.map((item) => (
                    <div className="col-12 user_chat mb-3">
                      <div className="row">
                        <MessageBox
                          position={
                            item?.replyBy === "Buyer" ? "right" : "left"
                          }
                          type={"text"}
                          title={item?.replyBy}
                          text={item?.message}
                          date={item?.createdAt}
                        />
                      </div>
                    </div>
                  ))} */}
                  {/* <div ref={ref}>_____</div> */}
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <form className="message_send row mx-0 w-100" action="">
                <div className="form-group col">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Type a Message...."
                    // onChange={handleMessage}
                    // value={newMessage}
                  />
                </div>

                <div className="form-group col-auto ps-0">
                  <button
                    className="send_btn"
                    type="send"
                    // onClick={(e) => {
                    //   e.preventDefault();
                    //   sendMessage();
                    // }}
                  >
                    <i className="fab fa-telegram-plane" />
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Help;
