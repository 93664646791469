import React, { useState, useEffect } from "react";
import Sidebar from "./Sidebar";
import Spinner from "./Spinner";
import { Link } from "react-router-dom";
import axios from "axios";
import {
  useFilterDashboardByDateQuery,
  useGetDashboardCountQuery,
} from "../services/Post";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment } from "@fortawesome/free-solid-svg-icons";

function DashboardNew(props) {
  const [loading, setLoading] = useState(true);
  const { data: dashboardCount } = useGetDashboardCountQuery();
  const [dashBoardList, setDashboardList] = useState([]);
  const [totalRevenue, setTotalRevenue] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const { data: filteredDashboard } = useFilterDashboardByDateQuery(
    {
      from: fromDate,
      to: toDate,
    },
    {
      skip: !fromDate || !toDate,
    }
  );

  axios.defaults.headers.common["x-auth-token-admin"] =
    localStorage.getItem("token");

  useEffect(() => {
    props.setProgress(10);
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_APIENDPOINT}admin/dashboards-home`)
      .then((response) => {
        setDashboardList(response?.data?.results?.listData.reverse());
        props.setProgress(100);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (dashboardCount) {
      setTotalRevenue(dashboardCount?.results?.orderRevenue);
      const calculatedTotalRevenue = dashboardCount.results.orderRevenue.reduce(
        (sum, item) => sum + (item.total || 0),
        0
      );
      setTotalRevenue(calculatedTotalRevenue);
    }
  }, [dashboardCount]);

  useEffect(() => {
    if (filteredDashboard?.results?.listData) {
      setDashboardList(filteredDashboard.results.listData);
    }
  }, [filteredDashboard]);

  const handlePageReload = () => {
    setTimeout(() => {
      window?.location?.reload();
    }, 500);
  };

  return (
    <>
      {loading}
      <Sidebar Dash={"dashboard"} />
      <div className="admin_main">
        <div className="admin_main_inner">
          <div className="admin_panel_data height_adjust">
            <div className="row dashboard_part justify-content-center">
              <div className="col-12">
                <div className="row ms-0 mb-3 justify-content-start">
                  <div className="col-4 d-flex align-items-stretch mb-4">
                    <a href="#" className="row dashboard_box box_design w-100">
                      <div className="col-auto px-0">
                        <span className="dashboard_icon">
                          <i className="fas fa-user" />
                        </span>
                      </div>
                      <div className="col pe-0">
                        <div className="dashboard_boxcontent">
                          <h2>Total Users</h2>
                          <span> {dashboardCount?.results?.countUser} </span>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="col-4 d-flex align-items-stretch mb-4">
                    <a href="#" className="row dashboard_box box_design w-100">
                      <div className="col-auto px-0">
                        <span className="dashboard_icon">
                          <i className="far fa-dollar-sign" />
                        </span>
                      </div>
                      <div className="col pe-0">
                        <div className="dashboard_boxcontent">
                          <h2>Total Revenue</h2>
                          <span>{totalRevenue} </span>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="col-4 d-flex align-items-stretch mb-4">
                    <a href="#" className="row dashboard_box box_design w-100">
                      <div className="col-auto px-0">
                        <span className="dashboard_icon">
                          <i className="fas fa-file-alt" />
                        </span>
                      </div>
                      <div className="col pe-0">
                        <div className="dashboard_boxcontent">
                          <h2>Open Support Tickets</h2>
                          <span>
                            {" "}
                            {dashboardCount?.results?.supportsTicket}{" "}
                          </span>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="row mx-0">
                  <div className="col-12 design_outter_comman shadow">
                    <div className="row comman_header justify-content-between">
                      <div className="col-auto">
                        <h2>Recent Orders</h2>
                      </div>
                    </div>
                    <form
                      className="form-design py-4 px-3 help-support-form row align-items-end justify-content-between"
                      action=""
                    >
                      <div className="form-group mb-0 col-5">
                        <label htmlFor="fromDate">From</label>
                        <input
                          type="date"
                          className="form-control"
                          id="fromDate"
                          value={fromDate}
                          onChange={(e) => setFromDate(e.target.value)}
                        />
                      </div>
                      <div className="form-group mb-0 col-5">
                        <label htmlFor="toDate">To</label>
                        <input
                          type="date"
                          className="form-control"
                          id="toDate"
                          value={toDate}
                          onChange={(e) => setToDate(e.target.value)}
                        />
                      </div>
                      <div className="form-group mb-0 col-auto">
                        <Link
                          className="comman_btn"
                          // onClick={handleDateFilter}
                          disabled
                        >
                          <span>Search</span>
                        </Link>
                      </div>
                    </form>
                    {loading ? (
                      <Spinner />
                    ) : (
                      <div className="row">
                        <div className="col-12 comman_table_design px-0">
                          <div className="table-responsive">
                            <table className="table mb-0">
                              <thead>
                                <tr>
                                  <th>S.No.</th>
                                  <th>User NAME</th>
                                  <th>Mobile Number</th>
                                  <th>Order Date</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {dashBoardList?.map((item, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>{index + 1} </td>
                                      <td>
                                        {" "}
                                        {item?.firstName
                                          ? item?.firstName
                                          : item?.firstName_ar}{" "}
                                        {item?.lastName
                                          ? item?.lastName
                                          : item?.lastName_ar}{" "}
                                      </td>
                                      <td>{item?.user_Id?.mobileNumber}</td>
                                      <td> {item?.createdAt?.slice(0, 10)} </td>
                                      <td>
                                        <Link
                                          className="comman_btn table_viewbtn"
                                          to={`/recent-order/${encodeURIComponent(
                                            JSON.stringify(item)
                                          )}`}
                                        >
                                          <span>View</span>
                                        </Link>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Link
        className="styles-module_whatsappButton__tVits floating-whatsapp-button"
        aria-hidden="true"
        to={"/message"}
        onClick={handlePageReload}
      >
        <img
          src="./assets/img/whatsappnew.svg"
          alt="WhatsApp"
          className="icon-width"
          style={{ color: "white", height: "45px" }}
        />
        <span className="position-absolute top-0 start-10  translate-middle badge bg-danger  text-light shadow">
          1<span className="visually-hidden">unread messages</span>
        </span>
      </Link>
      <div
        className="modal fade reply_modal"
        id="staticBackdropchat"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered"
          style={{ marginRight: "100px" }}
        >
          <div className="modal-content border-0">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                Chat
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>

            <div className="modal-body py-4 " id="chat">
              <div
                className="chatpart_main "
                id="chat2"
                //  ref={chatpartMainRef}
              >
                <div className="row mx-0 ">
                  <div className="col-12 user_chat mb-3">
                    <div className="row">
                      <div className="col-12 user_chat mb-md-3 mb-4">
                        <div className="row mx-0 justify-content-start">
                          <div className="col-auto">
                            <a href="#" className="chat_comman_pix">
                              <img
                                src={"assets/img/product1.png"}
                                height={50}
                                width={50}
                                alt=""
                              />
                            </a>
                          </div>
                          <div className="col-5 ps-0">
                            <div className="chat_comman_content">
                              <span>Me</span>
                              <p>
                                <img src="assets/img/waving-hand.png" alt="" />{" "}
                                Hey David Moore, are you available ? ..
                              </p>
                              <p className="">1:00 PM</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 mine_chat mb-md-3 mb-4">
                        <div className="row mx-0 justify-content-end">
                          <div className="col-5 pe-0">
                            <div className="chat_comman_content2 text-end">
                              <span>David Moore</span>
                              <p>Yes i am available. Lorem Ipsum . </p>
                            </div>
                          </div>
                          <div className="col-auto">
                            <Link to="#" className="chat_comman_pix">
                              <img
                                src={"assets/img/product1.png"}
                                height={50}
                                width={50}
                                alt=""
                              />
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 user_chat mb-md-3 mb-4">
                        <div className="row mx-0 justify-content-start">
                          <div className="col-auto">
                            <Link to="#" className="chat_comman_pix">
                              <img
                                src={"assets/img/product1.png"}
                                height={50}
                                width={50}
                                alt=""
                              />
                            </Link>
                          </div>
                          <div className="col-5 ps-0">
                            <div className="chat_comman_content">
                              <span>Me</span>
                              <p>
                                <img src="assets/img/waving-hand.png" alt="" />{" "}
                                Hey David Moore, are you available ? ..
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 mine_chat mb-md-3 mb-4">
                        <div className="row mx-0 justify-content-end">
                          <div className="col-5 pe-0">
                            <div className="chat_comman_content2 text-end">
                              <span>David Moore</span>
                              <p>Yes i am available. Lorem Ipsum . </p>
                            </div>
                          </div>
                          <div className="col-auto">
                            <Link to="#" className="chat_comman_pix">
                              <img
                                src={"assets/img/product1.png"}
                                height={50}
                                width={50}
                                alt=""
                              />
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 user_chat mb-md-3 mb-4">
                        <div className="row mx-0 justify-content-start">
                          <div className="col-auto">
                            <Link to="#" className="chat_comman_pix">
                              <img
                                src={"assets/img/product1.png"}
                                height={50}
                                width={50}
                                alt=""
                              />
                            </Link>
                          </div>
                          <div className="col-5 ps-0">
                            <div className="chat_comman_content">
                              <span>Me</span>
                              <p className="">
                                Hey David Moore, are you available ? ..
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 mine_chat mb-md-3 mb-4">
                        <div className="row mx-0 justify-content-end">
                          <div className="col-5 pe-0">
                            <div className="chat_comman_content2 text-end">
                              <span>David Moore</span>
                              <p>Yes i am available. Lorem Ipsum . </p>
                            </div>
                          </div>
                          <div className="col-auto">
                            <a href="#" className="chat_comman_pix">
                              <img
                                src={"assets/img/product1.png"}
                                height={50}
                                width={50}
                                alt=""
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                      {/* <MessageBox
                        position={"right"}
                        type={"text"}
                        // title={mainChat?.buyer?.full_name}
                        // text={mainChat?.concern}
                        // date={mainChat?.createdAt}
                      /> */}
                    </div>
                    {/* {mainChat?.images?.map((item) => (
                      <div className="row mt-1">
                        <MessageBox
                          position={"right"}
                          type={"photo"}
                          title={mainChat?.buyer?.full_name}
                          date={mainChat?.createdAt}
                          data={{
                            uri: item,
                            width: 50,
                          }}
                        />
                      </div>
                    ))} */}
                  </div>
                  {/* {(chat || [])?.map((item) => (
                    <div className="col-12 user_chat mb-3">
                      <div className="row">
                        <MessageBox
                          position={
                            item?.replyBy === "Buyer" ? "right" : "left"
                          }
                          type={"text"}
                          title={item?.replyBy}
                          text={item?.message}
                          date={item?.createdAt}
                        />
                      </div>
                    </div>
                  ))} */}
                  {/* <div ref={ref}>_____</div> */}
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <form className="message_send row mx-0 w-100" action="">
                <div className="form-group col">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Type a Message...."
                    // onChange={handleMessage}
                    // value={newMessage}
                  />
                </div>

                <div className="form-group col-auto ps-0">
                  <button
                    className="send_btn"
                    type="send"
                    // onClick={(e) => {
                    //   e.preventDefault();
                    //   sendMessage();
                    // }}
                  >
                    <i className="fab fa-telegram-plane" />
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DashboardNew;
