import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import Sidebar from "./Sidebar";
import {
  useAgentsListQuery,
  useDeleteOrderListMutation,
  useFilterCompleteOrderByDateQuery,
  useFilterOrderByDateQuery,
  useGetCompletedOrderListAllQuery,
  useGetOrderListAllQuery,
  useOrderAssignMutation,
} from "../services/Post";
import Spinner from "./Spinner";

function OrderManagement(props) {
  const { data: agents } = useAgentsListQuery();
  const [loading, setLoading] = useState(true);
  const { data: orderListAll } = useGetOrderListAllQuery();
  const { data: completedOrderListAll } = useGetCompletedOrderListAllQuery();
  const [assignOrder] = useOrderAssignMutation();
  const [deleteOrder] = useDeleteOrderListMutation();
  const [itemId3, setItemId3] = useState("");
  const [orderList, setOrderList] = useState([]);
  const [completedOrderList, setCompletedOrderList] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [fromDate1, setFromDate1] = useState("");
  const [toDate1, setToDate1] = useState("");
  const [agentList, setAgentList] = useState([]);
  const [selectedBrandIds, setSelectedBrandIds] = useState([]);

  useEffect(() => {
    if (agents) {
      setAgentList(agents?.results?.list);
    }
  }, [agents]);

  const { data: filteredOrder } = useFilterOrderByDateQuery(
    {
      from: fromDate,
      to: toDate,
    },
    {
      skip: !fromDate || !toDate,
    }
  );

  useEffect(() => {
    if (filteredOrder?.results?.order) {
      setOrderList(filteredOrder?.results?.order);
    }
  }, [filteredOrder]);

  const { data: filteredOrdercompleted } = useFilterCompleteOrderByDateQuery(
    {
      from: fromDate1,
      to: toDate1,
    },
    {
      skip: !fromDate1 || !toDate1,
    }
  );

  useEffect(() => {
    if (filteredOrdercompleted?.results?.CompletedOrder) {
      setCompletedOrderList(filteredOrdercompleted?.results?.CompletedOrder);
    }
  }, [filteredOrdercompleted]);

  useEffect(() => {
    if (orderListAll) {
      props.setProgress(10);
      setLoading(true);
      setOrderList(orderListAll?.results?.order?.slice()?.reverse());
      setLoading(false);
      props.setProgress(100);
    }
  }, [orderListAll]);

  useEffect(() => {
    if (completedOrderListAll) {
      setCompletedOrderList(completedOrderListAll?.results?.CompletedOrder);
    }
  }, [completedOrderListAll]);

  const handleInputChange1 = (event, index) => {
    const { value } = event.target;
    setSelectedBrandIds((prevSelectedBrandIds) => {
      const updatedSelectedBrandIds = [...prevSelectedBrandIds];
      updatedSelectedBrandIds[index] = value;
      return updatedSelectedBrandIds;
    });
  };

  const handleSelectChange = async (e, itemId, index, item) => {
    if (item?.orderStatus === "Pending") {
      Swal.fire({
        title: "Status Pending!",
        text: "Please change the status first.",
        icon: "warning",
        confirmButtonText: "OK",
      }).then((result) => {
        if (result.isConfirmed) {
          // window.location.reload();
        }
      });
    } else {
      console.log("itemId", itemId);
      e.preventDefault();
      handleInputChange1(e, index);

      const updatedSelectedBrandIds = [...selectedBrandIds];
      updatedSelectedBrandIds[index] = e.target.value;

      const data = {
        id: itemId,
        deliverdBy: updatedSelectedBrandIds.filter(Boolean),
      };
      try {
        await assignOrder(data);
        Swal.fire({
          title: "Changes Saved",
          text: "The Order has been Assigned successfully.",
          icon: "success",
          confirmButtonText: "OK",
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        });
      } catch (error) {}
    }
  };
  return (
    <>
      {loading}
      <Sidebar Dash={"orders"} />
      <div className="admin_main">
        <div className="admin_main_inner">
          <div className="admin_panel_data height_adjust">
            <div className="row buyersseller justify-content-center">
              <div className="col-12">
                <div className="row mx-0">
                  <div className="col-12 design_outter_comman shadow px-0">
                    <ul
                      className="nav nav-tabs comman_tabs"
                      id="myTab"
                      role="tablist"
                    >
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link active"
                          id="home-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#home"
                          type="button"
                          role="tab"
                          aria-controls="home"
                          aria-selected="true"
                        >
                          Orders
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="profile-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#profile"
                          type="button"
                          role="tab"
                          aria-controls="profile"
                          aria-selected="false"
                        >
                          Completed Orders
                        </button>
                      </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">
                      <div
                        className="tab-pane fade show active"
                        id="home"
                        role="tabpanel"
                        aria-labelledby="home-tab"
                      >
                        <div className="row px-4 py-4 mx-0">
                          <div className="col-12 inner_design_comman border">
                            <div className="row comman_header justify-content-between">
                              <div className="col-auto">
                                <h2>Orders</h2>
                              </div>
                            </div>
                            <form
                              className="form-design py-4 px-3 help-support-form row align-items-end justify-content-between"
                              action=""
                            >
                              <div className="form-group mb-0 col-5">
                                <label htmlFor="fromDate">From</label>
                                <input
                                  type="date"
                                  className="form-control"
                                  id="fromDate"
                                  value={fromDate}
                                  onChange={(e) => setFromDate(e.target.value)}
                                />
                              </div>
                              <div className="form-group mb-0 col-5">
                                <label htmlFor="toDate">To</label>
                                <input
                                  type="date"
                                  className="form-control"
                                  id="toDate"
                                  value={toDate}
                                  onChange={(e) => setToDate(e.target.value)}
                                />
                              </div>
                              <div className="form-group mb-0 col-2">
                                <Link
                                  className="comman_btn d-flex w-100"
                                  disabled
                                >
                                  <span>Search</span>
                                </Link>
                              </div>
                            </form>
                            <div className="row">
                              <div className="col-12 comman_table_design px-0">
                                <div className="table-responsive">
                                  <table className="table mb-0">
                                    <thead>
                                      <tr>
                                        <th>S.No.</th>
                                        <th>Full Name</th>
                                        <th>Email Id</th>
                                        <th>Order Date</th>
                                        <th>Order Amount </th>
                                        <th>Payment Method</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                        <th>ASSIGN DELIVERY BOY</th>
                                      </tr>
                                    </thead>
                                    {loading ? (
                                      <div
                                        className="d-flex align-items-end justify-content-end "
                                        style={{ marginLeft: "450px" }}
                                      >
                                        <Spinner />
                                      </div>
                                    ) : (
                                      <tbody>
                                        {orderList?.map((item, index) => {
                                          return (
                                            <tr key={index}>
                                              <td> {index + 1} </td>
                                              <td>
                                                {" "}
                                                {item?.firstName
                                                  ? item?.firstName
                                                  : item?.firstName_ar}{" "}
                                                {item?.lastName}{" "}
                                              </td>
                                              <td>
                                                {item?.user_Id?.userEmail}
                                              </td>
                                              <td>
                                                {" "}
                                                {item?.createdAt?.slice(
                                                  0,
                                                  10
                                                )}{" "}
                                              </td>
                                              <td>
                                                {item?.total ? item?.total : 0}
                                              </td>
                                              <td> {item?.paymentMethod} </td>
                                              <td>
                                                {" "}
                                                <div
                                                  className={
                                                    item?.orderStatus ===
                                                    "Cancelled"
                                                      ? "text-danger"
                                                      : item?.orderStatus ===
                                                        "Pending"
                                                      ? "text-warning"
                                                      : item?.orderStatus ===
                                                        "Packed"
                                                      ? "text-info"
                                                      : item?.orderStatus ===
                                                        "Approved"
                                                      ? "text-success"
                                                      : item?.orderStatus ===
                                                        "In_Progress"
                                                      ? "text-primary"
                                                      : item?.orderStatus ===
                                                        "Delivered"
                                                      ? "text-success"
                                                      : "text-default"
                                                  }
                                                  style={{
                                                    background:
                                                      item?.orderStatus ===
                                                      "Cancelled"
                                                        ? "#ffe5e5"
                                                        : item?.orderStatus ===
                                                          "Pending"
                                                        ? "#fff6e5"
                                                        : item?.orderStatus ===
                                                          "Packed"
                                                        ? "#e5f0ff"
                                                        : item?.orderStatus ===
                                                          "Approved"
                                                        ? "#e5ffe5"
                                                        : item?.orderStatus ===
                                                          "In_Progress"
                                                        ? "#e5e5ff"
                                                        : item?.orderStatus ===
                                                          "Delivered"
                                                        ? "#e5ffe5"
                                                        : "#f9f9f9",
                                                    borderRadius: "5px",
                                                    padding: "2px 5px",
                                                  }}
                                                >
                                                  {item?.orderStatus ===
                                                  "In_Progress"
                                                    ? "In Progress"
                                                    : item?.orderStatus}
                                                </div>{" "}
                                              </td>
                                              <td>
                                                <Link
                                                  className="comman_btn table_viewbtn"
                                                  // to="/upcoming-orders"
                                                  to={`/upcoming-orders/${encodeURIComponent(
                                                    JSON.stringify(item)
                                                  )}`}
                                                >
                                                  <span>View</span>
                                                </Link>
                                                <Link
                                                  className="comman_btn bg-danger table_viewbtn ms-1"
                                                  to="#"
                                                  onClick={() => {
                                                    Swal.fire({
                                                      title: "Are you sure?",
                                                      text: "You won't be able to revert this!",
                                                      icon: "warning",
                                                      showCancelButton: true,
                                                      confirmButtonColor:
                                                        "#3085d6",
                                                      cancelButtonColor: "#d33",
                                                      confirmButtonText:
                                                        "Yes, delete it!",
                                                    }).then((result) => {
                                                      if (result.isConfirmed) {
                                                        deleteOrder(item?._id);
                                                        Swal.fire(
                                                          "Deleted!",
                                                          `${item?._id}  item has been deleted.`,
                                                          "success"
                                                        ).then(() => {
                                                          const updatedOfferList =
                                                            orderList.filter(
                                                              (offer) =>
                                                                offer._id !==
                                                                item?._id
                                                            );
                                                          setOrderList(
                                                            updatedOfferList
                                                          );
                                                        });
                                                      }
                                                    });
                                                  }}
                                                >
                                                  <span>Delete</span>
                                                </Link>
                                              </td>
                                              <td>
                                                {item?.assignStatus ===
                                                "Assign" ? (
                                                  <span
                                                    style={{
                                                      cursor: "not-allowed",
                                                    }}
                                                  >
                                                    {item?.deliverdBy?.userName}
                                                  </span>
                                                ) : (
                                                  <div className="form-group col-12">
                                                    <select
                                                      className="select form-control"
                                                      multiple=""
                                                      name={`brandId1_${index}`}
                                                      id={`brandId1_${index}`}
                                                      value={
                                                        selectedBrandIds[
                                                          index
                                                        ] || ""
                                                      }
                                                      onChange={(e) =>
                                                        handleSelectChange(
                                                          e,
                                                          item?._id,
                                                          index,
                                                          item
                                                        )
                                                      }
                                                    >
                                                      <option
                                                        value=""
                                                        style={{
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        Assign
                                                      </option>
                                                      {Array.isArray(
                                                        agentList
                                                      ) &&
                                                        agentList.map(
                                                          (agent) => (
                                                            <option
                                                              key={agent._id}
                                                              value={agent._id}
                                                              style={{
                                                                textAlign:
                                                                  "center",
                                                              }}
                                                            >
                                                              {agent.userName}
                                                            </option>
                                                          )
                                                        )}
                                                    </select>
                                                  </div>
                                                )}
                                              </td>
                                            </tr>
                                          );
                                        })}
                                      </tbody>
                                    )}
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="profile"
                        role="tabpanel"
                        aria-labelledby="profile-tab"
                      >
                        <div className="row px-4 py-4 mx-0">
                          <div className="col-12 inner_design_comman border">
                            <div className="row comman_header justify-content-between">
                              <div className="col-auto">
                                <h2>Complete Order</h2>
                              </div>
                            </div>
                            <form
                              className="form-design py-4 px-3 help-support-form row align-items-end justify-content-between"
                              action=""
                            >
                              <div className="form-group mb-0 col-5">
                                <label htmlFor="fromDate1">From</label>
                                <input
                                  type="date"
                                  className="form-control"
                                  id="fromDate1"
                                  value={fromDate1}
                                  onChange={(e) => setFromDate1(e.target.value)}
                                />
                              </div>
                              <div className="form-group mb-0 col-5">
                                <label htmlFor="toDate1">To</label>
                                <input
                                  type="date"
                                  className="form-control"
                                  id="toDate1"
                                  value={toDate1}
                                  onChange={(e) => setToDate1(e.target.value)}
                                />
                              </div>
                              <div className="form-group mb-0 col-2">
                                <Link
                                  className="comman_btn d-flex w-100"
                                  disabled
                                >
                                  <span>Search</span>
                                </Link>
                              </div>
                            </form>
                            <div className="row">
                              <div className="col-12 comman_table_design px-0">
                                <div className="table-responsive">
                                  <table className="table mb-0">
                                    <thead>
                                      <tr>
                                        <th>S.No.</th>
                                        {/* <th>Image </th> */}
                                        <th>Full Name</th>
                                        <th>Email Id</th>
                                        <th>Order Date</th>
                                        <th>Order Amount</th>
                                        <th>Payment Method</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {completedOrderList?.map(
                                        (item, index) => {
                                          return (
                                            <tr key={index}>
                                              <td> {index + 1} </td>
                                              {/* <td>
                                                <img
                                                  src={item?.delivered_Img}
                                                  alt="Image Not Found"
                                                  height={50}
                                                  width={50}
                                                />
                                              </td> */}
                                              <td>
                                                {item?.firstName
                                                  ? item?.firstName
                                                  : item?.firstName_ar}{" "}
                                                {item?.lastName
                                                  ? item?.lastName
                                                  : item?.lastName_ar}
                                              </td>
                                              <td>xyz@gmail.com</td>
                                              <td>
                                                {item?.createdAt?.slice(0, 10)}
                                              </td>
                                              <td>{item?.total} SAR</td>
                                              <td>{item?.paymentMethod}</td>
                                              <td>
                                                <div
                                                  className={
                                                    item?.orderStatus ===
                                                    "Cancelled"
                                                      ? "text-danger"
                                                      : item?.orderStatus ===
                                                        "Pending"
                                                      ? "text-warning"
                                                      : item?.orderStatus ===
                                                        "Packed"
                                                      ? "text-info"
                                                      : item?.orderStatus ===
                                                        "Approved"
                                                      ? "text-success"
                                                      : item?.orderStatus ===
                                                        "Inprogress"
                                                      ? "text-primary"
                                                      : item?.orderStatus ===
                                                        "Delivered"
                                                      ? "text-secondary"
                                                      : "text-default"
                                                  }
                                                  style={{
                                                    background:
                                                      item?.orderStatus ===
                                                      "Cancelled"
                                                        ? "#ffe5e5"
                                                        : item?.orderStatus ===
                                                          "Pending"
                                                        ? "#fff6e5"
                                                        : item?.orderStatus ===
                                                          "Packed"
                                                        ? "#e5f0ff"
                                                        : item?.orderStatus ===
                                                          "Approved"
                                                        ? "#e5ffe5"
                                                        : item?.orderStatus ===
                                                          "Inprogress"
                                                        ? "#e5e5ff"
                                                        : item?.orderStatus ===
                                                          "Delivered"
                                                        ? "#f3f3f3"
                                                        : "#f9f9f9",
                                                    borderRadius: "5px",
                                                    padding: "2px 5px",
                                                  }}
                                                >
                                                  {item?.orderStatus}
                                                </div>
                                              </td>
                                              <td>
                                                <Link
                                                  className="comman_btn table_viewbtn"
                                                  // to="/completed-orders"
                                                  to={`/completed-orders/${encodeURIComponent(
                                                    JSON.stringify(item)
                                                  )}`}
                                                >
                                                  <span>View</span>
                                                </Link>
                                                <Link
                                                  className="comman_btn bg-danger table_viewbtn ms-1"
                                                  to="#"
                                                  onClick={() => {
                                                    Swal.fire({
                                                      title: "Are you sure?",
                                                      text: "You won't be able to revert this!",
                                                      icon: "warning",
                                                      showCancelButton: true,
                                                      confirmButtonColor:
                                                        "#3085d6",
                                                      cancelButtonColor: "#d33",
                                                      confirmButtonText:
                                                        "Yes, delete it!",
                                                    }).then((result) => {
                                                      if (result.isConfirmed) {
                                                        deleteOrder(item?._id);
                                                        Swal.fire(
                                                          "Deleted!",
                                                          `${item?._id}  item has been deleted.`,
                                                          "success"
                                                        ).then(() => {
                                                          const updatedOfferList =
                                                            completedOrderList.filter(
                                                              (offer) =>
                                                                offer._id !==
                                                                item?._id
                                                            );
                                                          setCompletedOrderList(
                                                            updatedOfferList
                                                          );
                                                        });
                                                      }
                                                    });
                                                  }}
                                                >
                                                  <span>Delete</span>
                                                </Link>
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OrderManagement;
