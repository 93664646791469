import React from "react";

function getSortedUserList(userList, messages) {
  return userList.results.list.slice().sort((userA, userB) => {
    const userAMessages = messages?.filter(
      (msg) => msg?.chatId?._id === userA._id
    );
    const userBMessages = messages?.filter(
      (msg) => msg?.chatId?._id === userB._id
    );

    const userALastMessage =
      userAMessages && userAMessages.length > 0
        ? new Date(userAMessages[userAMessages.length - 1].createdAt).getTime()
        : 0;

    const userBLastMessage =
      userBMessages && userBMessages.length > 0
        ? new Date(userBMessages[userBMessages.length - 1].createdAt).getTime()
        : 0;

    return userBLastMessage - userALastMessage;
  });
}

export default getSortedUserList;
