import React, { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import Sidebar from "./Sidebar";
import { useNavigate, useParams } from "react-router-dom";
import { useUpdateOrderStatusMutation } from "../services/Post";

function UpcomingOrderView() {
  const [orderStatus, setOrderStatus] = useState([]);
  const [updateOrderStatus] = useUpdateOrderStatusMutation();
  axios.defaults.headers.common["x-auth-token-admin"] =
    localStorage.getItem("token");

  const { item } = useParams();
  const navigate = useNavigate();

  let parsedItem = null;
  try {
    if (item) {
      parsedItem = JSON.parse(decodeURIComponent(item));
    }
  } catch (error) {
    console.error("Error parsing JSON:", error);
  }

  if (!parsedItem) {
    console.error("Item data is missing or invalid.");
  }

  const handleSaveChanges = async (e) => {
    console.log("orderStatus", orderStatus);
    e.preventDefault();
    const editHelp = {
      id: parsedItem?._id,
      status: orderStatus.toString(),
      // status_ar: orderStatus,
    };
    try {
      await updateOrderStatus(editHelp);
      Swal.fire({
        icon: "success",
        title: "Changes Saved",
        text: "The Status has been updated successfully.",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
      }).then((result) => {
        if (result.isConfirmed) {
          // window.location.reload();
          navigate("/orders");
          setTimeout(() => {
            window.location.reload();
          }, 500);
        }
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred while updating the subcategory.",
      });
    }
  };

  console.log("parsedItem", parsedItem);
  return (
    <>
      <Sidebar Dash={"orders"} />
      <div className="admin_main">
        <div className="admin_main_inner">
          <div className="admin_panel_data height_adjust">
            <div className="row buyers-details justify-content-center">
              <div className="col-12">
                <div className="row mx-0">
                  <div className="col-12 design_outter_comman shadow mb-4 toggle_set">
                    <div className="row comman_header justify-content-between">
                      <div className="col-auto">
                        <h2> Order Details</h2>
                      </div>
                    </div>
                    <div className="row justify-content-center py-5">
                      <div className="col-10">
                        <form className="form-design row" action="">
                          <div className="form-group col-6">
                            <label htmlFor="quesstioon">Full Name</label>
                            <input
                              disabled=""
                              className="form-control"
                              type="text"
                              id="quesstioon"
                              name="quesstioon"
                              defaultValue={`${
                                parsedItem?.firstName
                                  ? parsedItem?.firstName
                                  : parsedItem?.firstName_ar
                              } ${
                                parsedItem?.lastName
                                  ? parsedItem?.lastName
                                  : parsedItem?.lastName_ar
                              }`}
                              readOnly
                            />
                          </div>
                          <div className="form-group col-6">
                            <label htmlFor="quesstioon">Email Id</label>
                            <input
                              disabled=""
                              className="form-control"
                              type="text"
                              id="quesstioon"
                              name="quesstioon"
                              defaultValue={parsedItem?.user_Id?.userEmail}
                              readOnly
                            />
                          </div>
                          <div className="form-group col-6">
                            <label htmlFor="quesstioon">Order Date</label>
                            <input
                              disabled=""
                              className="form-control"
                              type="text"
                              id="quesstioon"
                              name="quesstioon"
                              defaultValue={parsedItem?.createdAt?.slice(0, 10)}
                              readOnly
                            />
                          </div>
                          <div className="form-group col-6">
                            <label htmlFor="quesstioon">Order Amount</label>
                            <input
                              disabled=""
                              className="form-control"
                              type="text"
                              id="quesstioon"
                              name="quesstioon"
                              defaultValue={parsedItem?.total}
                              readOnly
                            />
                          </div>
                          <div className="form-group col-6">
                            <label htmlFor="quesstioon">Payment Method</label>
                            <input
                              disabled=""
                              className="form-control"
                              type="text"
                              id="quesstioon"
                              name="quesstioon"
                              defaultValue={parsedItem?.paymentMethod}
                              readOnly
                            />
                          </div>

                          {parsedItem?.orderStatus === "Delivered" ? (
                            <div className="form-group col-6">
                              <label htmlFor="quesstioon">Status</label>
                              <input
                                disabled=""
                                className="form-control"
                                name=""
                                id=""
                                style={{
                                  cursor: "not-allowed",
                                  background:
                                    parsedItem?.orderStatus === "Delivered"
                                      ? "#e5ffe5"
                                      : "#f9f9f9",
                                  color:
                                    parsedItem?.orderStatus === "Delivered"
                                      ? "green"
                                      : "inherit",
                                  fontWeight: "bold",
                                }}
                                defaultValue={parsedItem?.orderStatus}
                                readOnly
                              />
                            </div>
                          ) : (
                            <div className="form-group col-6">
                              <form>
                                <label htmlFor="quesstioon">Status</label>
                                <div className="form-floating ">
                                  <select
                                    className="form-select"
                                    id="floatingSelect12"
                                    aria-label="  select example"
                                    defaultValue={parsedItem?.orderStatus}
                                    style={{
                                      padding: "14px",
                                      height: "50px",
                                    }}
                                    onChange={(e) =>
                                      setOrderStatus(e.target.value)
                                    }
                                  >
                                    <option value="" disabled>
                                      Order Status
                                    </option>
                                    <option value="Delivered">Delivered</option>
                                    <option value="Cancelled">Cancelled</option>
                                    <option value="In_Progress">
                                      In Progress
                                    </option>
                                    <option value="Pending">Pending</option>
                                  </select>
                                </div>
                              </form>
                            </div>
                          )}

                          <div className="form-group col-12">
                            <label htmlFor="quesstioon">Address</label>
                            <textarea
                              disabled=""
                              className="form-control"
                              name=""
                              id=""
                              style={{ height: 100 }}
                              defaultValue={parsedItem?.address}
                              readOnly
                            />
                          </div>
                          {parsedItem?.orderStatus === "Delivered" ? (
                            <div className="form-group col-10 text-center mb-0">
                              <button
                                type="submit"
                                className="comman_btn"
                                disabled
                                style={{ cursor: "not-allowed" }}
                                title="Delivered! Can Not Update"
                              >
                                <span>Update Status</span>
                              </button>
                            </div>
                          ) : (
                            <div className="form-group col-10 text-center mb-0">
                              <button
                                type="submit"
                                className="comman_btn"
                                onClick={(e) => handleSaveChanges(e)}
                              >
                                <span>Update Status</span>
                              </button>
                            </div>
                          )}
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UpcomingOrderView;
