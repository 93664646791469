import React, { useRef } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../Sidebar";
import chat from "./chat.css";
import "./chat.css";
import { useState } from "react";
import { useEffect } from "react";
import { useCallback } from "react";
import { toast } from "react-toastify";
import socket from "./socket";
import {
  useAdminOfflineStatusMutation,
  useAdminOnlineStatusMutation,
  useGetUserListAllQuery,
  useSeenUnseenMutation,
} from "../../services/Post";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCheckDouble } from "@fortawesome/free-solid-svg-icons";
import FormattedTime from "./FormattedTime";
import getSortedUserList from "./SortedUserList";

function Message(props) {
  const [onlineAdmin] = useAdminOnlineStatusMutation();
  const [offlineAdmin] = useAdminOfflineStatusMutation();
  const [seenUnseen] = useSeenUnseenMutation();
  const [show, setShow] = useState(false);
  const [showMoreMap, setShowMoreMap] = useState({});
  const [showToday, setShowToday] = useState(true);
  const { data: userList } = useGetUserListAllQuery();
  const [messages, setMessages] = useState([]);
  const [seenMessages, setSeenMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const patentauserloginId = localStorage?.getItem("loginId");
  const [loading, setLoading] = useState(true);
  const [userListAll, setUserListListAll] = useState([]);
  const [userId, setUserId] = useState("");
  const [playIncoming, setPlayIncoming] = useState(false);
  const [playOutgoing, setPlayOutgoing] = useState(false);
  const [incomingMessageTriggered, setIncomingMessageTriggered] =
    useState(false);
  const [prevMessageCount, setPrevMessageCount] = useState(0);

  useEffect(() => {
    if (playIncoming) {
      const incomingAudio = new Audio("./assets/notification.wav");
      incomingAudio.play();
      setPlayIncoming(false);
    }
  }, [playIncoming]);

  useEffect(() => {
    if (playOutgoing) {
      const outgoingAudio = new Audio("./assets/notification.wav");
      outgoingAudio.play();
      setPlayOutgoing(false);
    }
  }, [playOutgoing]);

  useEffect(() => {
    if (patentauserloginId) {
      onlineAdmin(patentauserloginId);
    }
  }, []);

  useEffect(() => {
    const handleAdminOffline = async () => {
      if (patentauserloginId) {
        try {
          await offlineAdmin(patentauserloginId);
          setMessages([]);
          setSeenMessages([]);
          setInputMessage("");
        } catch (error) {
          console.error("Error updating admin status to offline:", error);
          toast.error("Failed to set admin offline status");
        }
      }
    };

    return () => {
      handleAdminOffline();
    };
  }, [patentauserloginId]);

  const messagesEndRef = useRef(null);

  const scrollToBottom = useCallback(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
    }
  }, [messagesEndRef, messages]);

  useEffect(() => {
    if (userList) {
      props.setProgress(10);

      const sortedUserList = getSortedUserList(userList, messages);

      setLoading(true);
      setUserListListAll(sortedUserList);
      setLoading(false);
      props.setProgress(100);
    }
  }, [userList, messages]);

  useEffect(() => {
    if (!socket.connected) {
      socket.on("connect", () => {
        console.log("socket_id", socket.id);
      });
    }

    socket.emit("createRoom", patentauserloginId);
  }, [inputMessage, patentauserloginId, socket]);

  socket.on("adminMessageList", (msgs) => {
    setMessages(msgs);

    const lastMessage = msgs[msgs.length - 1];
    if (lastMessage && lastMessage.sentBy !== "Admin") {
      setPlayIncoming(true);
    }
  });

  const payload = {
    chatId: userId?._id,
    text: inputMessage,
    lastMessage: "false",
    senderId: patentauserloginId,
    sentBy: "Admin",
  };

  const sendMessage = () => {
    if (!inputMessage) {
      toast.error("Enter a Message");
      return;
    }
    socket.emit("sendMessage", payload);
    setInputMessage("");
    scrollToBottom();
    socket.on("adminMessageList", (msgs) => {
      setMessages(msgs);
      setPlayOutgoing(true);
    });
  };

  const selectedUserMessages = messages?.filter(
    (msg) => msg?.chatId?._id === userId?._id
  );

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && e.shiftKey) {
      setInputMessage((prevMessage) => prevMessage + "\n");
      e.preventDefault();
    } else if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      sendMessage(e);
    }
  };

  const lastMessage = {
    chatId: messages[messages?.length - 1]?._id,
  };
  // const messageId = messages[messages?.length - 1]?._id;
  const messageId = selectedUserMessages[selectedUserMessages.length - 1]?._id;

  const handleSeen = async (messageId) => {
    if (messageId) {
      const response = await seenUnseen(messageId);
    }
  };

  const handleUser = (user) => {
    setUserId(user);
    setShow(true);
    handleSeen(messageId);
  };

  useEffect(() => {
    if (userId) {
      socket.emit("senderList", lastMessage);
    }
  }, [userId]);

  const handleToday = () => {
    scrollToBottom();
    setShowToday(false);
  };

  const handleToggleMore = (msgId) => {
    setShowMoreMap((prevShowMoreMap) => ({
      ...prevShowMoreMap,
      [msgId]: !prevShowMoreMap[msgId],
    }));
  };
  const handleToggleMore2 = (msgId) => {
    setShowMoreMap((prevShowMoreMap) => ({
      ...prevShowMoreMap,
      [msgId]: !prevShowMoreMap[msgId],
    }));
  };

  return (
    <>
      <Sidebar Dash={"dashboard"} />

      <div className="admin_main">
        <div className="admin_main_inner">
          <div className="admin_panel_data height_adjust">
            <div className="message_chat">
              <div className="row">
                <div className="col-lg-5 m-lg-0 mb-md-5 mb-4">
                  {/* <div className="col-lg-4 m-lg-0 mb-md-4 mb-4"> */}
                  <div className="message_left pe-xl-5">
                    <div className="message_left_top">
                      <div className="dropdown all_message">
                        <a
                          className="btn btn-secondary"
                          href="#"
                          role="button"
                          id="dropdownMenuLink"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          All Messages{" "}
                          {/* <img
                            className="ms-2"
                            src={require("../assets/img/arrowdown_color.png")}
                            alt=""
                          /> */}
                        </a>
                        {/* <div
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuLink"
                        >
                          <a href="#">New Group</a>
                          <a href="#">New Cummunity</a>
                          <a href="#">Selects Chat</a>
                          <a href="settings.html">Settings</a>
                          <a href="#">Logout</a>
                        </div> */}
                      </div>
                      {/* <div className="search_main">
                        <a href="#">
                          <img
                            src={require("../assets/img/Search_drk.png")}
                            alt=""
                          />
                        </a>
                        <div className="search_mainbox d-none">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search"
                          />
                        </div>
                      </div> */}
                    </div>
                    <div className="chat_user_show">
                      {userListAll?.map((user, ind) => {
                        const userMessages = messages?.filter(
                          (msg) => msg?.chatId?._id === user?._id
                        );

                        const lastMessage = userMessages
                          ? userMessages[userMessages.length - 1]
                          : null;

                        const messageCount = userMessages
                          ? userMessages.length
                          : 0;

                        return (
                          <Link
                            to="#"
                            className="row chatuser_box align-items-center"
                            key={ind}
                            onClick={(e) => {
                              handleUser(user);
                              scrollToBottom();
                            }}
                          >
                            <div className="col-auto">
                              <div className="user_img">
                                <img
                                  src={
                                    user?.profilePic ||
                                    user?.websiteProfile ||
                                    require("../assets/img/userpro.png")
                                  }
                                  alt=""
                                  style={{
                                    border: "2px solid #53B0EE",
                                    borderRadius: "100%",
                                    height: "50px",
                                    width: "50px",
                                  }}
                                />
                                {user?.userMode === true ? (
                                  <span title="Online" />
                                ) : (
                                  <span className="span2" title="Offline" />
                                )}
                              </div>
                            </div>
                            <div className="col chatuser_name ps-0">
                              <h2>
                                {user?.userName
                                  ? user?.userName
                                  : user?.websiteName}{" "}
                              </h2>
                              {lastMessage && (
                                <span>
                                  {lastMessage?.text?.length > 15
                                    ? `${lastMessage?.text?.slice(0, 15)}...`
                                    : lastMessage?.text}
                                </span>
                              )}
                            </div>
                            <div className="col-auto last_part text-end">
                              <div className="msg-time">
                                {lastMessage ? (
                                  <FormattedTime
                                    createdAt={lastMessage.createdAt}
                                  />
                                ) : null}
                              </div>
                              <div className="msg-count ms-auto">
                                {messageCount}{" "}
                              </div>
                            </div>
                          </Link>
                        );
                      })}
                    </div>
                  </div>
                </div>
                {show ? (
                  <div className="col-lg-7">
                    {/* <div className="col-lg-8"> */}
                    <div className="message_right">
                      <div className="chat_main">
                        <div className="chat_header row align-items-center mx-0">
                          <div className="col-auto">
                            <div className="chat_header_profile">
                              <img
                                src={
                                  userId?.profilePic ||
                                  userId?.websiteProfile ||
                                  require("../assets/img/userpro.png")
                                }
                                alt=""
                                style={{
                                  border: "2px solid #53B0EE",
                                  borderRadius: "100%",
                                  height: "50px",
                                  width: "50px",
                                }}
                              />
                              {userId?.userMode === true ? (
                                <span title="Online" />
                              ) : (
                                <span className="span2" title="Offline" />
                              )}
                            </div>
                          </div>
                          <div className="col px-0">
                            <div className="chat_header_text">
                              <a href="#">
                                {userId?.websiteName || userId?.userName}{" "}
                              </a>
                              <div className="chat_timee">
                                <span>
                                  {userId?.userMode === true
                                    ? "Online"
                                    : "Offline"}{" "}
                                </span>{" "}
                                |{" "}
                                <span>
                                  Member since {userId?.createdAt?.slice(0, 4)}{" "}
                                </span>
                              </div>
                            </div>
                          </div>
                          {/* <div className="col-auto d-flex align-items-center">
                            <a className="do_fav" href="#">
                              <img
                                src={require("../assets/img/star_blank.png")}
                                alt=""
                              />
                            </a>
                            <div className="dropdown chat_dropdown">
                              <a
                                className="btn btn-secondary"
                                href="#"
                                role="button"
                                id="dropdownMenuLink1"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <img
                                  src={require("../assets/img/bars.png")}
                                  alt=""
                                />
                              </a>
                              <div
                                className="dropdown-menu"
                                aria-labelledby="dropdownMenuLink1"
                              >
                                <a href="#">Report</a>
                                <a href="#">Block</a>
                                <a href="#">Delete Chat</a>
                                <a href="#">Close Chat</a>
                                <a href="#">Mute Notifications</a>
                                <a href="#">Select Message</a>
                              </div>
                            </div>
                          </div> */}
                        </div>
                        <div className="chat_mid pt-0" ref={messagesEndRef}>
                          <div className="row">
                            {showToday ? (
                              <div
                                className="col-12 timer_label mb-3"
                                style={{
                                  position: "sticky",
                                  top: "0",
                                  cursor: "pointer",
                                }}
                                onClick={handleToday}
                              >
                                <div className="row mx-0">
                                  <div className="col-12 text-center">
                                    <span>Today</span>
                                  </div>
                                </div>
                              </div>
                            ) : null}

                            {selectedUserMessages?.length > 0 &&
                              selectedUserMessages?.map((msg, ind) => {
                                const isLastMessage = msg.isRead === true;
                                const showMore = showMoreMap[msg._id];
                                const showMore2 = showMoreMap[msg._id];

                                return (
                                  <React.Fragment key={ind}>
                                    {msg?.sentBy === "User" ? (
                                      <div className="col-12 user_chat mb-md-3 mb-4">
                                        <div className="row mx-0 justify-content-start">
                                          <div className="col-auto">
                                            <a
                                              href="#"
                                              className="chat_comman_pix"
                                            >
                                              <img
                                                src={
                                                  userId?.profilePic ||
                                                  userId?.websiteProfile ||
                                                  require("../assets/img/userpro.png")
                                                }
                                                alt=""
                                                style={{
                                                  border: "2px solid #53B0EE",
                                                  borderRadius: "100%",
                                                  height: "30px",
                                                  width: "30px",
                                                }}
                                              />
                                            </a>
                                          </div>
                                          <div className="col-5 ps-0">
                                            <div className="chat_comman_content4">
                                              <span>
                                                <strong
                                                  style={{ color: "#fc4503" }}
                                                >
                                                  {msg?.chatId?.userName}
                                                </strong>{" "}
                                              </span>

                                              <p>
                                                {showMore2
                                                  ? msg?.text || ""
                                                  : msg?.text.length > 100
                                                  ? msg?.text.substring(
                                                      0,
                                                      100
                                                    ) + "..."
                                                  : msg?.text || ""}
                                              </p>

                                              {msg?.text.length > 100 && (
                                                <p
                                                  className="text-primary"
                                                  onClick={() =>
                                                    handleToggleMore2(msg._id)
                                                  }
                                                  style={{
                                                    cursor: "pointer",
                                                  }}
                                                >
                                                  {showMore2
                                                    ? "Hide More..."
                                                    : "Read More..."}
                                                </p>
                                              )}
                                              <p
                                                className="text-secondary"
                                                style={{
                                                  fontSize: "14px",
                                                  marginBottom: "-5px",
                                                  display: "flex",
                                                  alignItems: "end",
                                                  justifyContent: "end",
                                                }}
                                              >
                                                {new Date(
                                                  msg?.createdAt
                                                ).toLocaleTimeString("en-IN", {
                                                  hour: "2-digit",
                                                  minute: "2-digit",
                                                })}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="col-12 mine_chat mb-md-3 mb-4">
                                        <div className="row mx-0 justify-content-end">
                                          <div className="col-5 additional-class pe-0">
                                            <div className="chat_comman_content3 text-start">
                                              <strong>Doorbell </strong>

                                              <p>
                                                {showMore
                                                  ? msg?.text || ""
                                                  : msg?.text.length > 100
                                                  ? msg?.text.substring(
                                                      0,
                                                      100
                                                    ) + "..."
                                                  : msg?.text || ""}
                                              </p>

                                              {msg?.text.length > 100 && (
                                                <p
                                                  className="text-primary"
                                                  onClick={() =>
                                                    handleToggleMore(msg._id)
                                                  }
                                                  style={{
                                                    cursor: "pointer",
                                                  }}
                                                >
                                                  {showMore
                                                    ? "Hide More..."
                                                    : "Read More..."}
                                                </p>
                                              )}
                                              <div
                                                style={{
                                                  fontSize: "14px",
                                                  marginBottom: "-5px",
                                                  display: "flex",
                                                  alignItems: "end",
                                                  justifyContent: "end",
                                                }}
                                              >
                                                <p className="text-secondary">
                                                  {new Date(
                                                    msg?.createdAt
                                                  ).toLocaleTimeString(
                                                    "en-IN",
                                                    {
                                                      hour: "2-digit",
                                                      minute: "2-digit",
                                                    }
                                                  )}
                                                </p>
                                                <p className="ms-2">
                                                  {!isLastMessage ? (
                                                    <>
                                                      <FontAwesomeIcon
                                                        icon={faCheckDouble}
                                                        style={{
                                                          color: "#120403",
                                                        }}
                                                      />
                                                    </>
                                                  ) : (
                                                    <FontAwesomeIcon
                                                      icon={faCheckDouble}
                                                      style={{
                                                        color: "#4a8af7",
                                                      }}
                                                    />
                                                  )}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-auto">
                                            <Link
                                              to="#"
                                              className="chat_comman_pix"
                                            >
                                              <img
                                                src={
                                                  msg?.senderId?.adminProfile ||
                                                  require("../assets/img/pic2.png")
                                                }
                                                alt=""
                                                style={{
                                                  border: "2px solid #53B0EE",
                                                  borderRadius: "100%",
                                                  height: "30px",
                                                  width: "30px",
                                                }}
                                              />
                                            </Link>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </React.Fragment>
                                );
                              })}
                          </div>
                        </div>
                        <div className="chat_bottombar">
                          <div className="bottombar">
                            {/* <Link className="emoji_btn" to="#">
                              <img
                                src={require("../assets/img/emoji.png")}
                                alt=""
                              />
                            </Link>
                            <Link className="attechment_btn" to="#">
                              <img
                                src={require("../assets/img/attechment.png")}
                                alt=""
                              />
                            </Link> */}
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Send message..."
                              value={inputMessage}
                              onChange={(e) => setInputMessage(e.target.value)}
                              onKeyUp={(e) => handleKeyPress(e)}
                            />
                            <Link
                              className="send_btn"
                              to="#"
                              onClick={(e) => {
                                sendMessage(e);
                                scrollToBottom();
                              }}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <img
                                src={require("../assets/img/send.png")}
                                alt=""
                              />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="col-lg-7">
                    <div className="message_right" style={{ height: "100%" }}>
                      <div
                        className="chat_main"
                        style={{
                          height: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div className="row">
                          <div className="col-auto">
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <div className="">
                                <img
                                  src="../assets/img/whatsappnew.svg"
                                  alt="WhatsApp"
                                  className="icon-width"
                                  style={{ color: "white", height: "85px" }}
                                />
                              </div>
                              <strong className="mt-3">Chat For Admin</strong>
                              <div>Send and receive message</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Message;
